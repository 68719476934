import spinner from '../assets/img/loading-spinner.png';

export function LoadingSpinner(props: { id?: string }) {
  return (
    <div
      id={props.id}
      className='w-screen h-full absolute left-0 top-0 flex items-center justify-center z-45 bg-black bg-opacity-60'
    >
      <div className='w-45 h-16 bg-lp-black-001 border-2 border-black-001 rounded-xl text-white text-base font-bold flex items-center justify-center'>
        <img src={spinner} alt='spinner' className='w-7.5 h-7.5 mr-4' />
        <span>Loading...</span>
      </div>
    </div>
  );
}
